import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <footer>
      <div className="main-content">
        <div className="left box">
          <h2>About us</h2>
          <div className="content">
            <p>Welcome to Shree Ram Garments, where fashion meets quality in the heart of our curated collection. Nestled in the vibrant cityscape, our garments shop is a haven for those who seek style without compromise.</p>
            <div className="social">
              <a href="https://www.facebook.com/profile.php?id=61555538820923&is_tour_dismissed=true" target='blank'><span className="fab fa-facebook-f"></span></a>
              <a href="https://www.instagram.com/seoonegg/" target='blank'><span className="fab fa-instagram"></span></a>
             
            </div>
          </div>
        </div>
        <div className="center box">
          <h2>Quick Links</h2>
          <div className="content dis">
         
              <span className="text">Shop</span><br/>
           
              <span className="text">Login</span><br/>
       
     
              <span className="text">Privicy Policy</span><br/>
         
      
              <span className="text">Register</span><br/>
              <span className="text">Contact us</span><br/>
              
        
          </div>
        </div>
        <div className="center box">
          <h2>Address</h2>
          <div className="content">
            <div className="place">
              <span className="fas fa-map-marker-alt"></span>
              <span className="text">Birendranagar, Surkhet</span>
            </div>
            <div className="email">
            <span className="fas fa-user"></span>

              <span className="text"> Narender Singh </span>
            </div>
            <div className="phone">
              <span className="fas fa-phone-alt"></span>
             <div>
             <span className="text">+91  8595530849</span> <br></br>
                            <span className="text">+91 8368647680</span> <br></br>
                            <span className="text">+91 8745986480</span>
             </div>
            </div>
            <div className="email">
              <span className="fas fa-envelope"></span>
              <span className="text">info@seone-g7g.com</span>
            </div>
          </div>
        </div>
        <div className="right box">
          <h2>Sign Up Newsletter</h2>
          <div className="content">
            <form action="#">
              <div className="email">
                <div className="text">Email *</div>
                <input type="email" required />
              </div>
              
              <div className="btn">
                <button type="submit">Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="bottom">
        <center>
          <span className="credit">Design By <a href="https://www.dgmt.in">DGMT</a> | </span>
          <span className="far fa-copyright"></span><span> 2024 All rights reserved.</span>
        </center>
      </div>
    </footer>
  )
}

export default Footer